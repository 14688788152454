import React from "react"

const Footer = () => {
    return(
        <footer className="absolute bottom-0 left-0 w-full flex justify-center items-center py-8 md:py-4 px-4 z-10">
            <p className="text-white text-2xl capitalize text-center tk-playfair-display">
            All Rights Reserved Melramo Estate 2022. <a href="https://tensq.com.au" target="_blank" rel="noreferrer" className="transition-all hover:opacity-75">Developed by Ten Squared.</a>
            </p>
        </footer>
    )
}

export default Footer