import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ComingSoon from "../sections/comingSoon";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`melramo estate`, `1289 Daylesford-Malmsbury Rd, Denver VIC 3461`, `Denver VIC`]}
        title="Coming Soon"
      />

      <ComingSoon/>

    </Layout>
  );
}

export default IndexPage;
