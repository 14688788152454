import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./footer";

function Layout({ children }) {
  return (
      <>
      <Helmet>
      <script src="https://unpkg.com/blotterjs-fork@0.1.0/build/blotter.min.js" />

      </Helmet>
      <main className="bg-black relative">
        {children}
      </main>
      <Footer/>
      </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
